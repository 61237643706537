import * as action from '../store/actions';
import store from '../store';
import Http from '../Http';

export const loginByEmailAndPassword = credentials => {
  return dispatch =>
    new Promise((resolve, reject) => {
      return Http.post('/api/v1/admin/login', credentials)
        .then(res => {
          const { access_token: token } = res.data.data.payload;
          store.dispatch(
            action.authLogin({
              token,
            }),
          );
          return resolve(true);
        })
        .catch(err => {
          return reject({ errors: err.message });
        });
    });
};
