import logo200Image from 'assets/img/logo.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as AuthServices from '../services/authServices';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      submitted: false,
      response: null,
    };
  }

  // loginIn = credentials => {
  //   this.setState({ loading: true, response: null });
  //   this.props.dispatch(
  //     AuthServices.loginByEmailAndPassword({ ...credentials }),
  //   );
  // };

  render() {
    const {
      showLogo,
      usernameLabel,
      passwordLabel,
      children,
      isAuthenticated,
    } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={async (values, actions) => {
          this.setState({ loading: true, response: null });
          actions.setSubmitting(true);
          try {
            await this.props.dispatch(
              AuthServices.loginByEmailAndPassword(values),
            );
            this.setState({ ...this.state, loading: false });
            actions.setSubmitting(false);
          } catch (error) {
            this.setState({ ...this.state, loading: false });
            actions.setErrors({ response: 'Incorrect email or password.' });
            actions.setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().email().required('Email is required'),
          password: Yup.string()
            .required('Password is required')
            .min(6, 'Password has at least 6 characters'),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          const { response } = errors;
          return (
            <Form onSubmit={handleSubmit}>
              {showLogo && (
                <div className="bg-gradient-theme-left text-center p-2 mb-4">
                  <img
                    src={logo200Image}
                    className="rounded"
                    style={{ width: 120, height: 80, cursor: 'pointer' }}
                    alt="logo"
                  />
                </div>
              )}
              {!this.state.submitted && this.state.response && (
                <div style={{ color: 'orangered', textAlign: 'center' }}>
                  {this.state.response}
                </div>
              )}
              <FormGroup>
                <Label for={usernameLabel}>{usernameLabel}</Label>
                <Input
                  name="username"
                  type="email"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <div style={{ color: 'orangered' }}>{errors.email}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label for={passwordLabel}>{passwordLabel}</Label>
                <Input
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password && (
                  <div style={{ color: 'orangered' }}>{errors.password}</div>
                )}
              </FormGroup>
              {response && <div style={{ color: 'orangered' }}>{response}</div>}
              <hr />
              <Button
                type="submit"
                size="lg"
                className="bg-gradient-theme-left border-0"
                block
                onClick={handleSubmit}
                disabled={this.state.loading}
              >
                Login
              </Button>
              {children}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

AuthForm.propTypes = {
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  passwordLabel: PropTypes.string,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps)(withRouter(AuthForm));
