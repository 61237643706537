import GAListener from 'components/GAListener';
import {EmptyLayout, LayoutRoute, MainLayout} from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import {connect} from 'react-redux';
import componentQueries from 'react-component-queries';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import './styles/reduction.scss';

const Users = React.lazy(() => import('pages/Users'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const UserDetails = React.lazy(() => import('pages/UserDetails'));
const SocialButterflies = React.lazy(() => import('pages/SocialButterflies'));
const OpenAiTokens = React.lazy(() => import('pages/OpenAiTokens'));
const Groups = React.lazy(() => import('pages/Groups'));
const GroupDetails = React.lazy(() => import('pages/GroupDetails'));
const Tags = React.lazy(() => import('pages/Tags'));

const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

export const getAppUrl = () => {
    return process.env.REACT_APP_IDR_FE_URL;
};

class App extends React.Component {
    render() {
        let {userExist, isAuthenticated} = this.props;
        const isAuth = userExist || isAuthenticated;
        return (
            <BrowserRouter basename={getBasename()}>
                <GAListener>
                    <Switch>
                        <LayoutRoute
                            exact
                            path="/login"
                            layout={EmptyLayout}
                            component={props => <AuthPage {...props} />}
                        />
                        <MainLayout breakpoint={this.props.breakpoint}>
                            <React.Suspense fallback={<PageSpinner/>}>
                                {!isAuth && <Redirect to="/login"/>}
                                <Route exact path="/dashboard" component={DashboardPage}/>
                                <Route exact path="/users/:id" component={UserDetails}/>
                                <Route exact path="/social-butterflies" component={SocialButterflies}/>
                                <Route exact path="/openai-tokens" component={OpenAiTokens}/>
                                <Route exact path="/groups" component={Groups}/>
                                <Route exact path="/groups/:id" component={GroupDetails}/>
                                <Route exact path="/tags" component={Tags}/>
                                <Route exact path="/" component={Users}/>
                            </React.Suspense>
                        </MainLayout>
                    </Switch>
                </GAListener>
            </BrowserRouter>
        );
    }
}

const query = ({width}) => {
    if (width < 576) {
        return {breakpoint: 'xs'};
    }

    if (576 <= width && width < 768) {
        return {breakpoint: 'sm'};
    }

    if (768 <= width && width < 991) {
        return {breakpoint: 'md'};
    }

    if (992 <= width && width < 1200) {
        return {breakpoint: 'lg'};
    }

    if (width >= 1200) {
        return {breakpoint: 'xl'};
    }

    return {breakpoint: 'xs'};
};

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps)(componentQueries(query)(App));
