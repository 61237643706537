import axios from 'axios';
import jwt_decode from 'jwt-decode';
import store from './store';
import * as actions from './store/actions';

const token = localStorage.getItem('access_token');
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
} else {
  delete axios.defaults.headers.common.Authorization;
}

try {
  let decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    localStorage.removeItem('access_token');
    window.location.href = '/login';
  }
} catch (err) {}

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch(actions.authLogout());
    }
    return Promise.reject(error);
  },
);

export default axios;
