import logo200Image from 'assets/img/logo.svg';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import {Link, NavLink} from 'react-router-dom';
import React from 'react';
import {MdDashboard, MdGroupAdd, MdPeople, MdShare} from 'react-icons/md';
import {IoIosPricetag} from 'react-icons/io';
import {Nav, Navbar, NavItem, NavLink as BSNavLink,} from 'reactstrap';
import bn from 'utils/bemnames';
import {GiBrainTentacle} from "react-icons/all";

const sidebarBackground = {
    backgroundImage: `url("${sidebarBgImage}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

const navItems = [
    {to: '/dashboard', name: 'dashboard', exact: false, Icon: MdDashboard},
    {to: '/', name: 'users', exact: true, Icon: MdPeople},
    {to: '/social-butterflies', name: 'social-butterflies', exact: true, Icon: MdShare},
    {to: '/groups', name: 'groups', exact: true, Icon: MdGroupAdd},
    {to: '/tags', name: 'tags', exact: true, Icon: IoIosPricetag},
    {to: '/openai-tokens', name: 'openai', exact: true, Icon: GiBrainTentacle}
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
    state = {
        isOpenComponents: false,
        isOpenContents: false,
        isOpenPages: false,
    };

    render() {
        return (
            <aside className={bem.b()} data-image={sidebarBgImage}>
                <div className={bem.e('background')} style={sidebarBackground}/>
                <div className={bem.e('content')}>
                    <Navbar>
                        <Link to="/" className="navbar-brand d-flex ml-auto mr-auto">
                            <img
                                src={logo200Image}
                                width="120"
                                height="60"
                                className="pr-2"
                                alt="logo"
                            />
                        </Link>
                        <h5 className="text-white mr-auto ml-auto">Admin panel</h5>
                    </Navbar>
                    <Nav vertical>
                        {navItems.map(({to, name, exact, Icon}, index) => (
                            <NavItem key={index} className={bem.e('nav-item')}>
                                <BSNavLink
                                    id={`navItem-${name}-${index}`}
                                    className="text-uppercase"
                                    tag={NavLink}
                                    to={to}
                                    activeClassName="active"
                                    exact={exact}
                                >
                                    <Icon className={bem.e('nav-item-icon')}/>
                                    <span className="">{name}</span>
                                </BSNavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </div>
            </aside>
        );
    }
}

export default Sidebar;
