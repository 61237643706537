import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import jwt_decode from 'jwt-decode';
import { authLogin, authLogout } from './store/actions';

const checkAuth = () => {
  const lsTest = () => {
    const test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  };
  let decoded;
  if (lsTest() === true) {
    if (
      localStorage.access_token !== undefined &&
      localStorage.access_token !== '' &&
      localStorage.access_token !== 'undefined' &&
      localStorage.access_token !== null
    ) {
      const token = localStorage.access_token;
      try {
        decoded = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          decoded = null;
          store.dispatch(authLogout());
          return false;
        } else {
          store.dispatch(authLogin({ token }));
          return true;
        }
      } catch (error) {
        return false;
      }
    } else {
      store.dispatch(authLogout());
      return false;
    }
  } else {
    store.dispatch(authLogout());
    return false;
  }
};

const isAuth = checkAuth();

ReactDOM.render(
  <Provider store={store}>
    <App userExist={isAuth} />
  </Provider>,
  document.getElementById('root'),
);
