import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import RootReducer from './reducers';

const store = createStore(RootReducer, applyMiddleware(ReduxThunk));

persistStore(store);

export default store;
