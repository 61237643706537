import * as ActionTypes from '../action-types';
import Http from '../../Http';
import jwtDecode from 'jwt-decode';

const defaultUser = {
  id: null,
  name: null,
  email: null,
};

const initialState = {
  inProgress: true,
  isAuthenticated: false,
  user: defaultUser,
};

const login = (state, data) => {
  let decoded;
  try {
    decoded = jwtDecode(data.token);
  } catch (error) {
    decoded = {};
  }

  Http.defaults.headers.common.Authorization = `Bearer ${data.token}`;

  localStorage.setItem('access_token', data.token);

  return Object.assign({}, state, {
    inProgress: false,
    isAuthenticated: true,
    user: decoded,
  });
};

const logout = state => {
  localStorage.removeItem('access_token');
  return Object.assign({}, state, {
    inProgress: false,
    isAuthenticated: false,
    user: defaultUser,
  });
};

const empty = state => {
  return Object.assign({}, state, {
    inProgress: false,
    isAuthenticated: false,
    user: defaultUser,
  });
};

const Auth = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.AUTH_LOGIN:
      return login(state, payload);
    case ActionTypes.AUTH_LOGOUT:
      return logout(state);
    case ActionTypes.AUTH_EMPTY:
      return empty(state);
    default:
      return state;
  }
};

export default Auth;
